import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import "./OtpVerification.css";

const OtpVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  let email = location.state?.email || '';
  const [otp, setOtp] = useState('');
  const [emails, setEmail] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isResending, setIsResending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [emailError, setEmailError] = useState('');
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!email) {
      setEmailError('Email is required');
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };
  const validateOtp = (otp) => {
    if (!otp) {
      setOtpError('Code is required');
    } else if (otp.length !== 6) {
      setOtpError('Code must be 6 digits');
    } else {
      setOtpError('');
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    validateOtp(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const initializeComponent = async () => {
      // Artificial delay to ensure proper initialization
      await new Promise(resolve => setTimeout(resolve, 100));
      
      if (email === '') {
        setOpen(true);
      }
      setIsLoading(false);
    };

    initializeComponent();
  }, [email]);

  const handleSubmits = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    e.preventDefault();
    if (!validateEmail(emails)) {
      Swal.fire({
        icon: 'error',
        title: 'Sending Failed',
        text: 'please enter correct email.',
      });
      return; // Stop submission if email is invalid
    }
    try {
      const response = await fetch('https://timesavor-server.onrender.com/api/buyer/sendOTPforuserwebsite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email : emails }),
      });

      const result = await response.json();
      console.log(response.status);

      if(response.status === 401){
        Swal.fire({
          icon: 'info',
          title: 'Already Verified',
          text: 'You are already verified. No need to resend the OTP.',
        });
        return
      }

      if(response.status === 400){
        Swal.fire({
          icon: 'info',
          title: 'User Not Registered',
          text: 'It seems like this account is not registered in our system. Please register first to continue.',
          footer: 'If you believe this is a mistake, contact support for assistance.',
        });
        return
        
      }

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Code sent',
          text: 'Code has been sent successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Sending Failed',
          text: 'Failed to Send Coding. Please try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again.',
      });
    } finally {
      handleClose()
      setIsSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    setIsResending(true);
    try {
      if(email === ''){
        email = emails
      }
      if (email === '') {
        if (email === '') {
          Swal.fire({
            icon: 'error',
            title: 'Failed to Resend the code',
            text: 'Error in getting the Email, Re-Enter the email',
            timer: 2000, // Show the timer for 3 seconds
            showConfirmButton: false, // Hide the confirm button
            timerProgressBar: true, // Enable progress bar for timer
            didClose: () => { // Callback function to trigger after Swal closes
              setOpen(true); // Open the dialog after Swal closes
            }
          });
        
          return;
        }
        
        return;
      }
      
      const response = await fetch('https://timesavor-server.onrender.com/api/buyer/sendOTPforuserwebsite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      if(response.status === 401){
        Swal.fire({
          icon: 'info',
          title: 'Already Verified',
          text: 'You are already verified. No need to resend the OTP.',
        });
        return
      }

      if(response.status === 400){
        Swal.fire({
          icon: 'info',
          title: 'User Not Registered',
          text: 'It seems like this account is not registered in our system. Please register first to continue.',
          footer: 'If you believe this is a mistake, contact support for assistance.',
        });
        return
        
      }
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Code Resent',
          text: result.message || 'Code has been resent successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Resend Failed',
          text: result.message || 'Failed to resend Code. Please try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again.',
      });
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(email === ''){
      email = emails
    }
    if(email === ''){
      Swal.fire({
        icon: 'error',
        title: 'Error in getting the Email, Re-Enter the email',
        text: 'Failed to verify Email. Please try again.',
      });
      return;
    }
    if (!otpError ) {
      try {
        const response = await fetch('https://timesavor-server.onrender.com/api/buyer/verifyOTPWebsite', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, otp }),
        });

        const result = await response.json();

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Verification Successful',
            text: 'Your email has been verified successfully!',
          }).then(() => {
            history.push('/LoginPage');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Verification Failed',
            text: result.message || 'Failed to verify Email. Please try again.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred. Please try again.',
        });
      }
    }
  };

  if (isLoading) {
    return null; // Or return a loading spinner if you prefer
  }

  return (
    <>
      <IndexNavbar />
      {!open && (
        <div className="otp-container">
          <div className="otp-card">
            <h1 className="otp-title">Verify Email</h1>
            <form onSubmit={handleSubmit} className="otp-form">
              <div className="form-field">
                <input
                  type="number"
                  id="otp"
                  onWheel={(e) => e.target.blur()}
                  value={otp}
                  onChange={(e) => {
                    if (e.target.value.length <= 6) {
                      handleOtpChange(e);
                    }
                  }}
                  className="form-input"
                  placeholder=" "
                  required
                />
               {email === '' ?  <p onClick={()=>setOpen(true)} style={{display:"flex",justifyItems : "self-start" , color:"#3f51b5" , textUnderlinePosition:"unset" , cursor:"pointer" , fontWeight : "600"}} >Re-Enter Email</p>:""}
                <label htmlFor="otp" className="form-label">Enter Code</label>
                {otpError && <span className="error-message">{otpError}</span>}
              </div>
              <button type="submit" className="submit-button" disabled={isResending}>
                Verify Email
              </button>
              <button 
                type="button" 
                className="resend-button" 
                onClick={handleResendOtp}
                disabled={isResending}
              >
                {isResending ? 'Resending...' : 'Resend Code'}
              </button>
            </form>
          </div>
        </div>
      )}

      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth 
        sx={{ 
          '& .MuiDialog-paper': { 
            height: '320px',
          } 
        }}
      >
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmits} className="otp-form">
            {/* <TextField
              id="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              placeholder="Enter your email"
              value={emails}
              onChange={handleEmailChange}
              required
            /> */}

<TextField
      id="email"
      label="Email"
      type="email"
      variant="outlined"
      fullWidth
      margin="normal"
      // placeholder="Enter your email"
      value={emails}
      onChange={handleEmailChange}
      required
      // error={!!emailError}
      helperText={emailError}
      sx={{
        '& .MuiFormHelperText-root': {
          color: theme => emailError ? theme.palette.error.main : 'inherit',
          marginLeft: '14px',
          marginRight: '14px'
        }
      }}
       />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
  type="submit"
  onClick={handleSubmits}
  className="submit-button"
  sx={{
    color: "white", 
    '&.Mui-disabled': {
      color: "white",
      // backgroundColor: '#1976d2',
      opacity: 0.7,
    }
  }}
  disabled={isSubmitting || emails.length === 0 || !!emailError}
>
  {isSubmitting ? 'Send...' : 'Send Code'}
</Button>

          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{
              '&:hover': {
                background: 'linear-gradient(45deg,)',
                transform: 'translateY(-2px)',
                boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OtpVerification;