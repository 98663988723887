import React, { useState, useEffect ,useRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Swal from 'sweetalert2';
import uploadIcon from './upload.png';
import showicon from './show.png';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage'; // We'll create this utility function below
import Modal from 'react-modal'; // Optional: for better modal handling
import 'react-image-file-resizer';

import hideicon from './hide.png';
import '../CustomCalendar.css'; 
import "./Register.css";
// import { 
//   suitabilityOptions, 
//   categoryTypesOptions, 
//   attractionsOptions, 
//   farmingOptions, 
//   grapeVarietalsOptions 
// } from '../../components/Options/options';
import { fetchStateOptions,getAllStateNames, getRegionsForState, getSubRegionsForStateAndRegion } from '../../components/Options/stateOptions'
import MultiSelect from '../../components/MultiSelect';
import MultiSelectGrape from '../../components/MultiSelectGrape'
import BusinessHours from 'components/Hours/BusinessHours';
const GOOGLE_MAPS_API_KEY = 'AIzaSyA16A5xGXypdMIWhyQdnmoYtBwPsgXwVTk';

const Register = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isWinery, setIsWinery] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); 
  const [profilePhoto, setProfilePhoto] = useState(null); // State for storing the profile photo
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppingImage, setCroppingImage] = useState(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [openMultiSelect, setOpenMultiSelect] = useState('');
  // Add these state variables for social media links
// State for social media links
const [facebook, setFacebook] = useState('');
const [instagram, setInstagram] = useState('');
const [twitter, setTwitter] = useState('');
const [shopTimeError, setShopTimeError] = useState('');
// State for validation errors
const [facebookError, setFacebookError] = useState('');
const [instagramError, setInstagramError] = useState('');
const [twitterError, setTwitterError] = useState('');



  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null); 
  const [profilePhotoError, setProfilePhotoError] = useState('');
  // Common fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [placesResults, setPlacesResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  // User-specific fields
  const [firstname, setFirstname] = useState('');
  const [username, setUsername] = useState('');
  const [dob,  setDob]= useState('');
  const [bio, setBio] = useState('');
  const [questionnaire, setQuestionnaire] = useState({
    beenToWineCountry: '',
    winePreference: '',
    wineClubsJoined: '',
    wineLoverLevel: ''
  });

  // Winery-specific fields
  const [heading, setHeading] = useState('');
  const [address, setAddress] = useState('');
  const [direction, setDirection] = useState('');
  const [mob, setMob] = useState('');
  const [description, setDescription] = useState('');
  const [shopTime, setShopTime] = useState('');
  const [deliverWithinMinutes, setDeliverWithinMinutes] = useState('');
  const [shopWebsiteURL, setShopWebsiteURL] = useState('');
  const [categoryTypes, setCategoryTypes] = useState('');
  const [price, setPrice] = useState('');
  const [state, setState] = useState('');
  const [region, setRegion] = useState('');
  const [subRegion, setSubRegion] = useState('');
  const [suitability, setSuitability] = useState('');
  const [attractions, setAttractions] = useState('');
  const [farming, setFarming] = useState('');
  const [grapeVarietals, setGrapeVarietals] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [winePreference, setWinePreference] = useState([]);
  const [shopLogo, setShopLogo] = useState(null); // State for storing the shop logo
const [shopLogoPreview, setShopLogoPreview] = useState(null); // State for storing the shop logo preview
const [shopLogoError, setShopLogoError] = useState(''); // State for handling errors with the shop logo
const [suitabilityOptions , setSuitabilityOptions] = useState([]);
   const [categoryTypesOptions , setCategoryTypesOptions] = useState([]);
   const [attractionsOptions , setAttractionsOptions] = useState([]);
   const [farmingOptions , setFarmingOptions] = useState([]);
   const [grapeVarietalsOptions , setGrapeVarietalsOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [query, setQuery] = useState('');
  const [imageError, setImageError] = useState('');
  const [shopImgError,setShopImgError] = useState('');

  // Define your API key
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  // Autocomplete hook

  const [autocomplete, setAutocomplete] = useState(null);
  const autocompleteService = useRef(null);
  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  console.log(shopTimeError)


  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    if (autocomplete) {
      autocomplete.setOptions({
        types: ['establishment'],
        fields: ['formatted_address', 'geometry', 'name'],
      });
      autocomplete.setBounds(new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(49.3457868, -66.93457),
        new window.google.maps.LatLng(24.396308, -125.00165),
      ));
      autocomplete.addListener('place_changed', handlePlaceChange);
    }
  };


  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        setAddress(place.formatted_address);
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(place.formatted_address)}`;
        setDirection(directionsUrl);
      }
    }
  };
  const handlePlaceChange = (place) => {
    if (place) {
      const formattedAddress = place.formatted_address;
      setAddress(formattedAddress);
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
      
      // Generate Google Maps directions URL
      const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(formattedAddress)}`;
      setDirection(directionsUrl);
    }
  };

  const searchPlaces = () => {
    setLoading(true);
    fetch(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${query}&key=${GOOGLE_MAPS_API_KEY}&region=us&language=en&maxResults=8`)  // Add maxResults parameter
      .then((response) => response.json())
      .then((data) => {
        setPlacesResults(data.results.slice(0, 8)); // Ensure to slice the first 8 results
        setNextPageToken(data.next_page_token);  // Store the next page token
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching places:', error);
        setLoading(false);
      });
  };

  const loadMorePlaces = () => {
    if (!nextPageToken) return;

    setLoadingMore(true);
    fetch(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${query}&key=${GOOGLE_MAPS_API_KEY}&region=us&language=en&pagetoken=${nextPageToken}`)  // Use nextPageToken for fetching more results
      .then((response) => response.json())
      .then((data) => {
        setPlacesResults((prevResults) => [...prevResults, ...data.results.slice(0, 8)]);  // Append more results
        setNextPageToken(data.next_page_token);  // Update the next page token
        setLoadingMore(false);
      })
      .catch((error) => {
        console.error('Error loading more places:', error);
        setLoadingMore(false);
      });
  };

  const validateURL = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})' + // domain name
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$',
      'i'
    );
    return !!urlPattern.test(url);
  };
  
  const validateFacebook = (url) => {
    if (url && !validateURL(url)) {
      setFacebookError('Please enter a valid Facebook URL.');
    } else {
      setFacebookError('');
    }
  };

  const handleMultiSelectOpen = (selectId) => {
    setOpenMultiSelect((prevOpenId) => (prevOpenId === selectId ? '' : selectId));
  };
  
  const validateInstagram = (url) => {
    if (url && !validateURL(url)) {
      setInstagramError('Please enter a valid Instagram URL.');
    } else {
      setInstagramError('');
    }
  };
  
  const validateTwitter = (url) => {
    if (url && !validateURL(url)) {
      setTwitterError('Please enter a valid Twitter URL.');
    } else {
      setTwitterError('');
    }
  };
  

  const handleCancelCrop = () => {
    setCropModalOpen(false); // Close the modal
    setCroppingImage(null);  // Clear the cropping image state
    setCrop({ x: 0, y: 0 }); // Reset crop position
    setZoom(1);              // Reset zoom level
  
    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  
  const handleShopLogoChange = (e) => {
    const file = e.target.files[0];
  
    // Validate file type
    const validFormats = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
      'image/x-icon',
      'image/heic'
    ];
  
    if (file && !validFormats.includes(file.type)) {
      setShopLogoError('Please upload a valid image file');
      e.target.value = ''; // Clear the file input
      return; // Stop further processing
    }
  
    if (file) {
      setShopLogo(file);
  
      // Generate a preview URL
      const previewUrl = URL.createObjectURL(file);
      setShopLogoPreview(previewUrl);
      setShopLogoError(''); // Clear any previous error message
    }
  };
  
  const handleRemoveShopLogo = () => {
    setShopLogo(null);
    setShopLogoPreview(null);
  };
  

  
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!email) {
      setEmailError('Email is required');
    } else if (!re.test(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState);
    setRegion('');
    setSubRegion('');
  };
  
  const handleRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);
    setSubRegion('');
  };
  
  const handleSubRegionChange = (selectedSubRegion) => {
    setSubRegion(selectedSubRegion);
  };
  const formatDateWithoutZ = (date) => {
    const isoString = date.toISOString(); // This gives you the full ISO string with Z
    return isoString.slice(0, -1); // Removes the last character, which is "Z"
  };
  

  const handleDobChange = (date) => {
    setDob(date);
    setIsCalendarOpen(false); // Close calendar after selecting a date
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen); // Toggle calendar visibility
  };
  const validatePassword = (password) => {
    if (!password) {
      setPasswordError('Password is required');
    } else if (password.length < 8) {
      setPasswordError('Use 8 characters password');
    } else {
      setPasswordError('');
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  
  const handleCropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(croppingImage, croppedAreaPixels);
      setProfilePhoto(croppedImage);
      setProfilePhotoPreview(URL.createObjectURL(croppedImage));
      setCropModalOpen(false); // Close the modal after cropping
    } catch (e) {
      console.error(e);
    }
  };
  
  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
  
    // Validate file type
    const validFormats = [
      'image/jpeg', 
      'image/png', 
      'image/gif', 
      'image/bmp', 
      'image/tiff', 
      'image/webp', 
      'image/svg+xml', 
      'image/x-icon', 
      'image/heic'
    ];
  
    if (file && !validFormats.includes(file.type)) {
      setProfilePhotoError('Please upload a valid image file');
      e.target.value = ''; // Clear the file input
      return;
    }
  
    if (file) {
      setCroppingImage(URL.createObjectURL(file));
      setCropModalOpen(true); // Open the crop modal
    }
  };

  const handleRemoveProfilePhoto = () => {
    setProfilePhoto(null);  // Clear the selected profile photo state
    setProfilePhotoPreview(null);  // Clear the profile photo preview
  
    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';  // This resets the file input
    }
  };
  
  const isBusinessHoursValid = () => {
    if (!shopTime) {
      setShopTimeError('Please select valid business hours.');
      return false;
    }
  
    const days = shopTime.split(';');
    for (let i = 0; i < days.length; i++) {
      const [day, timeRange] = days[i].split(', ');
      const [openTime, closeTime] = timeRange ? timeRange.split('-') : [];
      if (!openTime || !closeTime) {
        setShopTimeError(`Please complete the business hours for ${day}.`);
        return false;
      }
    }
  
    setShopTimeError(''); // Clear the error if valid
    return true;
  };
  
  

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      setConfirmPasswordError('Please confirm your password');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const fetchCategoryOptions = async () =>{
    try {
      const response = await fetch(
        "https://timesavor-server.onrender.com/api/buyer/getAllCatOptions"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch category types data.");
      }
      const data = await response.json();
      setSuitabilityOptions(data.Suitability || []);
      setCategoryTypesOptions(data.Experience || []);
      setAttractionsOptions(data.Attractions || []);
      setFarmingOptions(data.Farming || []);
      setGrapeVarietalsOptions(data.GrapeVarietals || []);
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  }

  useEffect(() => {
    fetchStateOptions();
    fetchCategoryOptions();
  }, []);

  useEffect(() => {
    const isWineryFormValid = isWinery
      ? email && password && heading && address && direction && mob && description && shopTime && deliverWithinMinutes && price
      : firstname && email && password && confirmPassword;

    setIsFormValid(
      isWineryFormValid &&
      !emailError && !passwordError && !confirmPasswordError
    );
  }, [email, password, confirmPassword, emailError, passwordError, confirmPasswordError, isWinery, firstname, heading, address, direction, mob, description, shopTime, deliverWithinMinutes, price,attractions]);

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFormats = [
      'image/jpeg', 
      'image/png', 
      'image/gif', 
      'image/bmp', 
      'image/tiff', 
      'image/webp', 
      'image/svg+xml', 
      'image/x-icon', 
      'image/heic'
    ];

    // Check if all selected files are valid images
    const validImages = selectedFiles.every(file => validFormats.includes(file.type));
    
    if (!validImages) {
      // Clear the input field
      e.target.value = ''; 
      
      // Set the error message
      setImageError('Only image files are allowed');
      return;
    }
    if (images.length + selectedFiles.length > 15) {
      // Clear the input field
      e.target.value = '';

      // Set the error message
      setImageError('You cannot upload more than 15 images');
      return;
    }
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
    setImageError('');
  };


  const isValidAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age >= 21;
  };
    const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Perform validation for social media links
    validateFacebook(facebook);
    validateInstagram(instagram);
    validateTwitter(twitter);
  
    if (facebookError || instagramError || twitterError) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fix the errors in the form before submitting.',
      });
      return;
    }
      if (isWinery) {
      if (images.length === 0) {
        setImageError('Please upload at least one image');
        return;
      }

      if(!shopLogo){
        setShopLogoError("Please upload Winery Logo");
        return;
      }
      if (!shopWebsiteURL || shopWebsiteURL.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'Website (Booking) link is Required',
          text: 'Please add a website booking link.',
        });
        return;
      }



      if (!state || state.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'State Required',
          text: 'Please select a state.',
        });
        return;
      }
  
      // Check if 'region' is present
      if (!region || region.trim() === '') {
        Swal.fire({
          icon: 'error',
          title: 'American Viticultural Area Required',
          text: 'Please select a American Viticultural Area.',
        });
        return;
      }

      if(password){
        const hasUppercase = /[A-Z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasMinLength = password.length >= 8;
      
        let errorMessage = '';
  
      
        if (!hasUppercase) {
          errorMessage = 'Password must include at least one uppercase letter.';
        } else if (!hasSpecialChar) {
          errorMessage = 'Password must include at least one special character.';
        } else if (!hasNumber) {
          errorMessage = 'Password must include at least one number.';
        } else if (!hasMinLength) {
          errorMessage = 'Password must be at least 8 characters long.';
        }
      
        if (errorMessage) {
          Swal.fire({
            icon: 'error',
            title: 'Current password is weak.',
            text: `${errorMessage}`,
          });
          setPasswordError(errorMessage)
          return;
        }
      };
    if(!shopTime){
      Swal.fire({
        icon: 'error',
        title: 'Select Business Time',
        text: 'Please select valid business hours.',
      });
      return;
    }

    } else {
      if (!firstname || !email || !password || !username) {
        Swal.fire({
          icon: 'error',
          title: 'Form Incomplete',
          text: 'Please fill out all required fields.',
        });
        return;
      }
      
      if(confirmPassword !== password){
        Swal.fire({
          icon: 'error',
          title: 'Password Not Match',
          text: 'Please Enter Same Password',
        });
        return;
      }
    }
  
    // Check if form is valid before submission
    if (isFormValid) {
      setLoading(true);
      try {
        let registrationData;
        let apiEndpoint;
  
        if (isWinery) {
          const formData = new FormData();
          formData.append('Heading', heading);
          formData.append('Address', address);
          formData.append('Direction', direction);
          formData.append('Mob', mob);
          formData.append('Description', description);
          formData.append('ShopTime', shopTime);
          formData.append('DeliverWithinMinutes', deliverWithinMinutes);
          formData.append('ShopWebsiteURL', shopWebsiteURL);
          formData.append('FacebookURL', facebook);
          formData.append('InstagramURL', instagram);
          formData.append('TwitterURL', twitter);
          formData.append('CategoryTypes', categoryTypes.length ? categoryTypes.join(',') : ''); 
          formData.append('Price', price);
          formData.append('State', state);
          formData.append('Region', region);
          formData.append('SubRegion', subRegion);
          formData.append('Suitability', suitability.length ? suitability.join(',') : '');
          formData.append('Attractions', attractions.length ? attractions.join(',') : '');
          formData.append('Farming', farming.length ? farming.join(',') : '');
          formData.append('GrapeVarietals', grapeVarietals.length ? grapeVarietals.join(',') : '');
          formData.append('Latitude', latitude);
          formData.append('Longitude', longitude);
          formData.append('email', email);
          formData.append('password', password);
          if (shopLogo) {
            formData.append('shopLogo', shopLogo);
          }
          images.forEach((image, index) => {
            formData.append('images', image, image.name);
          });
  
          registrationData = formData;
          apiEndpoint = 'https://timesavor-server.onrender.com/api/business/signup';
        } else {
          
          const formData = new FormData();
          formData.append('firstname', firstname);
          formData.append('email', email);
          formData.append('password', password);
          formData.append('username', username);
          formData.append('dob', dob.toISOString());
          formData.append('bio', bio);
  
          formData.append('questionnaire[beenToWineCountry]', questionnaire.beenToWineCountry || '');
          formData.append('questionnaire[winePreference]', winePreference.join(', ') || '');
          formData.append('questionnaire[wineClubsJoined]', questionnaire.wineClubsJoined || '');
          formData.append('questionnaire[wineLoverLevel]', questionnaire.wineLoverLevel || '');
  
         
          if (profilePhoto) {
            formData.append('profilePhoto', profilePhoto);
          }
  
          registrationData = formData;
          apiEndpoint = 'https://timesavor-server.onrender.com/api/buyer/signup';
        }
  
      
        for (const pair of registrationData.entries()) {
          console.log(pair[0], pair[1]);
        }
  
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          body: registrationData,  // Send FormData directly
        });
  
        const result = await response.json();
  
        if (response.ok) {
          try {
            const otpResponse = await fetch(
              'https://timesavor-server.onrender.com/api/buyer/sendOTPforuserwebsite',
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
              }
            );
  
            const otpResult = await otpResponse.json();
  
            if (otpResponse.ok) {
              Swal.fire({
                title: 'Registration Successful',
                text: `You have been registered successfully! A verification code has been sent to your email: ${email}.`,
              }).then(() => {
                setLoading(false);
                history.push('/otpVerification', { email });
              });
            } else {
              Swal.fire({
                title: 'Registration Successful',
                text: 'Registration was successful but failed to send verification code. Please try again.',
              });
            }
          } catch (otpError) {
            Swal.fire({
              title: 'Registration Successful',
              text: 'Registration was successful but an error occurred while sending verification code. Please try again.',
            });
          }
  
          if (!isWinery) {
            history.push('/loginPage');
          }
        } else {
          setLoading(false);
          Swal.fire({
            title: 'Registration Failed',
            text: result.message || 'Registration failed. Please try again.',
          });
        }
      } catch (error) {
        setLoading(false);
  
        let errorMessage = 'An error occurred. Please try again.'; // Default message
        if (error.response) {
          // If the server responded with an error, show the error message
          errorMessage = error.response.data.message || errorMessage;
        } else if (error.message) {
          // For other errors, show the error message
          errorMessage = error.message;
        }
  
        console.error('Error:', error); // Log the full error details in the console for debugging
  
        Swal.fire({
          title: 'Error',
          text: errorMessage, // Show the exact error message in the alert
        });
      }
    }
  };
  
  const handleWinePreferenceChange = (e) => {
    const { value, checked } = e.target;
    if (value === 'All') {
      if (checked) {
        setWinePreference(['Red', 'White']);
      } else {
        setWinePreference([]);
      }
    } else {
      if (checked) {
        setWinePreference((prev) => [...prev, value]);
      } else {
        setWinePreference((prev) => prev.filter((item) => item !== value));
      }
    }
  };
  
  
  return (
    <>
      <IndexNavbar />
      <div className="register-container">
        <div className="register-card">
          <h1 className="register-title">Create Account</h1>
          <div className="toggle-container">
            <button
              className={`toggle-button ${!isWinery ? 'active' : ''}`}
              onClick={() => setIsWinery(false)}
            >
              User
            </button>
            <button
              className={`toggle-button ${isWinery ? 'active' : ''}`}
              onClick={() => setIsWinery(true)}   
            >
              Winery
            </button>
          </div>
          <form onSubmit={handleSubmit} className="register-form">
            {/* Common fields */}
            <div className="form-field">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
                className="form-input"
                placeholder=" "
                required
              />
            <label htmlFor="email" className="form-label">
  Email* {isWinery && <span>(as it will appear in profile)</span>}
</label>

              {emailError && <span className="error-message" style={{marginTop:"20px"}}>{emailError}</span>}
            </div>
            <div className="form-field">
  <input
    type={showPassword ? "text" : "password"}
    id="password"
    value={password}
    onChange={(e) => {
      setPassword(e.target.value);
      validatePassword(e.target.value);
    }}
    onBlur={() => validatePassword(password)}
    className="form-input"
    placeholder=" "
    required
  />
  <label htmlFor="password" className="form-label">Password*</label>
  <img
    src={showPassword ? hideicon : showicon}
    alt="Toggle Password Visibility"
    className="password-toggle-icon"
    onClick={() => setShowPassword(!showPassword)}
  />
  {passwordError && <span className="error-message">{passwordError}</span>}
</div>
            {!isWinery && (
              <div className="form-field">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  validateConfirmPassword(e.target.value);
                }}
                onBlur={() => validateConfirmPassword(confirmPassword)}
                className="form-input"
                placeholder=" "
                required
              />
              <label htmlFor="confirmPassword" className="form-label">Confirm Password*</label>
              <img
                src={showConfirmPassword ? hideicon : showicon}
                alt="Toggle Confirm Password Visibility"
                className="password-toggle-icon"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
              {confirmPasswordError && <span className="error-message">{confirmPasswordError}</span>}
            </div>
            )}

            {/* User-specific fields */}
            {!isWinery && (
              <>
                <div className="form-field">
                  <input
                    type="text"
                    id="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="firstname" className="form-label"> Name*</label>
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="username" className="form-label">Username*</label>
                </div>
                <div className="custom-form-field">
  <input
    type="date"
    id="dob"
    value={dob ? dob.toISOString().split('T')[0] : ''}
    onChange={(e) => {
      const { value } = e.target;
      if (value) {
        setDob(new Date(value));
      } else {
        setDob(null);
      }
    }}
    className="custom-form-input"
    required
  />
  <label htmlFor="dob" className="custom-form-label">
    Date of Birth (At least 21 years of age)*
  </label>
  {dob && !isValidAge(dob) && (
    <span className="error-message">You must be at least 21 years old.</span>
  )}
</div>



                <div className="form-field">
  <textarea
    id="bio"
    value={bio}
    onChange={(e) => {
      if (e.target.value.length <= 150) {
        setBio(e.target.value);
      }
    }}
    className="form-input"
    placeholder=" "
    maxLength="150"
  />
  <label htmlFor="bio" className="form-label">Bio</label>
  <div className="character-count">
    {bio.length}/150 characters
  </div>
</div>
                <div className="form-field">
      <label>Have you been to wine country before?</label>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            value="Yes"
            checked={questionnaire.beenToWineCountry === 'Yes'}
            onChange={() => setQuestionnaire({ ...questionnaire, beenToWineCountry: 'Yes' })}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            value="No"
            style={{marginLeft:'5px'}}
            checked={questionnaire.beenToWineCountry === 'No'}
            onChange={() => setQuestionnaire({ ...questionnaire, beenToWineCountry: 'No' })}
          />
          No
        </label>
      </div>
    </div>
    
    <div className="form-field">
  <label>Which do you prefer?</label>
  <div className="checkbox-group form-checkbox-group">
    <label style={{ marginRight: '10px' }}>
      <input
        type="checkbox"
        value="Red"
        checked={winePreference.includes('Red')}
        onChange={handleWinePreferenceChange}
        style={{ marginRight: '5px' }}  // Adds space between checkbox and text
      />
      Red
    </label>
    <label style={{ marginRight: '10px' }}>
      <input
        type="checkbox"
        value="White"
        checked={winePreference.includes('White')}
        onChange={handleWinePreferenceChange}
        style={{ marginRight: '5px' }}  // Adds space between checkbox and text
      />
      White
    </label>
    <label>
      <input
        type="checkbox"
        value="All"
        checked={winePreference.includes('Red') && winePreference.includes('White')}
        onChange={handleWinePreferenceChange}
        style={{ marginRight: '5px' }}  // Adds space between checkbox and text
      />
      All
    </label>
  </div>
</div>



    
    <div className="form-field">
      <label htmlFor="wineClubsJoined">How many wine clubs have you joined?</label>
      <select
        id="wineClubsJoined"
        className="form-select"
        value={questionnaire.wineClubsJoined}
        onChange={(e) => setQuestionnaire({ ...questionnaire, wineClubsJoined: e.target.value })}
      >
        <option value="">Select</option>
        <option value="0-3">0-3</option>
        <option value="4-7">4-7</option>
        <option value="8-10">8-10</option>
        <option value="10+">10+</option>
      </select>
    </div>
    
    <div className="form-field">
      <label htmlFor="wineLoverLevel">What is your wine lover level?</label>
      <select
        id="wineLoverLevel"
        className="form-select"
        value={questionnaire.wineLoverLevel}
        onChange={(e) => setQuestionnaire({ ...questionnaire, wineLoverLevel: e.target.value })}
      >
        <option value="">Select</option>
        <option value="Novice">Novice</option>
        <option value="Enthusiast">Enthusiast</option>
        <option value="Connoisseur">Connoisseur</option>
        <option value="Collector">Collector</option>
      </select>
    </div>
    
    <div className="form-field">
      <label htmlFor="profilePhoto">Upload Profile Photo</label>
      <input
  type="file"
  id="profilePhoto"
  className="form-input"
  accept="image/*"
  onChange={handleProfilePhotoChange}
  ref={fileInputRef} // Add the reference here
/>

      {profilePhotoError && <span className="error-message">{profilePhotoError}</span>} {/* Display error message */}
      {profilePhotoPreview && (
        <div className="photo-preview-container">
          <img src={profilePhotoPreview} alt="Profile Preview" className="photo-preview" />
          <button
            type="button"
            className="remove-photo-button"
            onClick={handleRemoveProfilePhoto}
          >
            <i className="fas fa-times"></i> {/* FontAwesome "X" icon */}
          </button>
        </div>
        
      )}
    </div>
              </>
            )}

            {/* Winery-specific fields */}
            {isWinery && (
              <>
                <div className="form-field">
                  <input
                    type="text"
                    id="heading"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="heading" className="form-label">Winery Name*</label>
                </div>
              <div className="form-group">
  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
    <input
      type="text"
      id="address"

      className="form-input"
      value={address}
      onChange={handleAddressChange}
      placeholder="Search and select your address..."
      required
       autoComplete="off"
    />
  </Autocomplete>
  <label htmlFor="address" className="form-label">Address*</label>
</div>

                        
                           
                            <input
                             type="hidden"
        
                              value={direction}
                              onChange={(e) => setDirection(e.target.value)}
                              readOnly
                            />
                      
                        
                <div className="custom-form-field">
                <input
  type="tel"
  id="mob"
  value={mob.startsWith('+1') ? mob : `+1${mob}`}
  onChange={(e) => {
    let value = e.target.value;
    
    // Ensure input starts with "+1"
    if (!value.startsWith('+1')) {
      value = `+1${value.replace(/\D/g, '')}`; // Remove all non-numeric characters after "+1"
    } else {
      // Allow only numbers after "+1"
      value = `+1${value.slice(2).replace(/\D/g, '')}`;
    }

    // Limit length to 12 characters (+1 and 10 digits)
    if (value.length <= 12) {
      setMob(value);
    }
  }}
  className="custom-form-input"
  placeholder=" "
  required
  minLength="12" 
  maxLength="12"
  pattern="\+1\d{10}" 
/>

                  <label htmlFor="mob" className="custom-form-label">Phone Number*</label>
                </div>
                <div className="form-field">
  <textarea
    id="description"
    value={description}
    onChange={(e) => {
      if (e.target.value.length <= 150) {
        setDescription(e.target.value);
      }
    }}
    className="form-input"
    placeholder=" "
    maxLength="150"
    required
  />
  <label htmlFor="description" className="form-label">About*</label>
  <div className="character-count">
    {description.length}/150 characters
  </div>
</div>

<div className="form-field">
  <label htmlFor="shopTime" className="form-label">Business Hours (AM - PM)*</label>
  <BusinessHours 
    value={shopTime}
    onChange={(value) => {
      setShopTime(value);
      isBusinessHoursValid();  
    }}
    onBlur={isBusinessHoursValid} 
    className={`form-input ${shopTimeError ? 'error' : ''}`} 
  />
  {/* {shopTimeError && <span className="error-message">{shopTimeError}</span>}  */}
</div>


                {/* Continuing from where we left off */}
                <div className="form-field">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    id="deliverWithinMinutes"
                    value={deliverWithinMinutes}
                    onChange={(e) => setDeliverWithinMinutes(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                    min="1" 
                  />
                  <label htmlFor="deliverWithinMinutes" className="form-label">Experience Duration (minutes)*</label>
                </div>
                <div className="form-field">
                  <input
                    type="url"
                    id="shopWebsiteURL"
                    value={shopWebsiteURL}
                    onChange={(e) => setShopWebsiteURL(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                  />
                  <label htmlFor="shopWebsiteURL" className="form-label">Website (Booking Link)*</label>
                </div>
         
                <div className="form-field">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="form-input"
                    placeholder=" "
                    required
                    min="1" 
                  />
                  <label htmlFor="price" className="form-label">Tasting Fee ($)*</label>
                </div>
                <div className="custom-form-field">
  <select
    value={state}
    onChange={(e) => handleStateChange(e.target.value)}
    className="custom-form-input"
    required
  >
    <option value="">Select State</option>
    {getAllStateNames().map((stateName) => (
      <option key={stateName} value={stateName}>{stateName}</option>
    ))}
  </select>
  <label  className="custom-form-label">State*</label>
</div>

{state && (
  <div className="form-field">
    <select
      value={region}
      onChange={(e) => handleRegionChange(e.target.value)}
      className="form-input"
    >
      <option value="">Select American Viticultural Area</option>
      {getRegionsForState(state).map((regionName) => (
        <option key={regionName} value={regionName}>{regionName}</option>
      ))}
    </select>
    <label  className="form-label">American Viticultural Area*</label>
  </div>
)}

{state && region && getSubRegionsForStateAndRegion(state, region) && (
  <div className="form-field">
    <select
      value={subRegion}
      onChange={(e) => handleSubRegionChange(e.target.value)}
      className="form-input"
    >
      <option value="">Select Nested AVA</option>
      {getSubRegionsForStateAndRegion(state, region).map((subRegionName) => (
        <option key={subRegionName} value={subRegionName}>{subRegionName}</option>
      ))}
    </select>
    <label  className="form-label">Nested AVA</label>
  </div>
)}
                <div className="form-field">
  <MultiSelect
    options={categoryTypesOptions}
    value={categoryTypes}
    onChange={setCategoryTypes}
   className="form-input"
   isOpen={openMultiSelect === 'select1'}
   onDropdownToggle={() => handleMultiSelectOpen('select1')}
    // label="Category Types"
    
  />
  <label htmlFor="categoryTypes" className="form-label">Experience</label>
</div>
                <div className="form-field" >
  <MultiSelect
    options={suitabilityOptions}
    value={suitability}
    onChange={setSuitability}
    // label="Suitability"
      className="form-input"
      isOpen={openMultiSelect === 'select2'}
      onDropdownToggle={() => handleMultiSelectOpen('select2')}
  />
  <label htmlFor="suitability" className="form-label">Suitability</label>
</div>

<div className="form-field">
  <MultiSelect
    options={attractionsOptions}
    value={attractions}
    onChange={setAttractions}
    // label="Attractions"
    isOpen={openMultiSelect === 'select3'}
    onDropdownToggle={() => handleMultiSelectOpen('select3')}
  />
   <label htmlFor="attractions" className="form-label">Attractions</label>
</div>
<div className="form-field">
  <MultiSelect
    options={farmingOptions}
    value={farming}
    onChange={setFarming}
    // label="Farming/Winemaking"
    isOpen={openMultiSelect === 'select4'}
    onDropdownToggle={() => handleMultiSelectOpen('select4')}

  />
  <label htmlFor="farming" className="form-label">Farming/Winemaking</label>
</div>
<div className="form-field" >
  <MultiSelectGrape
    
    options={grapeVarietalsOptions}
    value={grapeVarietals}
    onChange={setGrapeVarietals}
 

    // label="Grape Varietals"
  />
   <label htmlFor="grapeVarietals" className="form-label">Grape Varietals and Wine Styles</label>
</div>
<div className="form-field">
      <input
        type="text"
        id="facebook"
        value={facebook}
        onChange={(e) => {
          setFacebook(e.target.value);
          validateFacebook(e.target.value);
        }}
        onBlur={() => validateFacebook(facebook)}
        className="form-input"
        placeholder=" "
      />
      <label htmlFor="facebook" className="form-label">Facebook (Optional)</label>
      {facebookError && <span className="error-message">{facebookError}</span>}
    </div>
    <div className="form-field">
      <input
        type="text"
        id="instagram"
        value={instagram}
        onChange={(e) => {
          setInstagram(e.target.value);
          validateInstagram(e.target.value);
        }}
        onBlur={() => validateInstagram(instagram)}
        className="form-input"
        placeholder=" "
      />
      <label htmlFor="instagram" className="form-label">Instagram (Optional)</label>
      {instagramError && <span className="error-message">{instagramError}</span>}
    </div>
    <div className="form-field">
      <input
        type="text"
        id="twitter"
        value={twitter}
        onChange={(e) => {
          setTwitter(e.target.value);
          validateTwitter(e.target.value);
        }}
        onBlur={() => validateTwitter(twitter)}
        className="form-input"
        placeholder=" "
      />
      <label htmlFor="twitter" className="form-label">Twitter (Optional)</label>
      {twitterError && <span className="error-message">{twitterError}</span>}
    </div>
                <div className="form-group">
                          
                            <input
                            type="hidden"
                              id="latitude"
                              className="form-control"
                              value={latitude}
                              onChange={(e) => setLatitude(e.target.value)}
                            />
                          </div>
                <div className="form-group">
                          
                            <input
                               type="hidden"
                              id="longitude"
                              className="form-control"
                              value={longitude}
                              onChange={(e) => setLongitude(e.target.value)}
                            />
                          </div>

                          <div className="form-field">
      <label htmlFor="shopLogo" className='custom-form-label' style={{marginTop : "-40px",marginLeft:"-15px",color: shopLogoPreview ? "#800020" : "#777777",}}>Upload Winery Logo</label>
      <input
        type="file"
        id="shopLogo"
        className="form-input"
        accept="image/*"
        required
        onChange={handleShopLogoChange}
      />
      {shopLogoError && <span className="error-message">{shopLogoError}</span>} {/* Display error message */}
      {shopLogoPreview && (
        <div className="photo-preview-container">
          <img src={shopLogoPreview} alt="Shop Logo Preview" className="photo-preview" />
          <button
            type="button"
            className="remove-photo-button"
            onClick={handleRemoveShopLogo}
          >
            <i className="fas fa-times"></i> {/* FontAwesome "X" icon */}
          </button>
        </div>
      )}
    </div>
    <div className="form-field">
  <div className="file-count">
    {images.length === 0 ? (
      <div className="custom-form-label" style={{marginTop :'-30px', marginLeft:'-15px' }}>Upload Gallery Images (Min-1) (Max-15)</div>
    ) : (
      `${images.length} image(s) selected`
    )}
  </div>
  <input
    type="file"
    id="images"
    onChange={handleImageChange}
    className="form-input"
    multiple
    accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg, .ico, .heic"
    required
  />
  {imageError && <div className="text-danger">{imageError}</div>}
</div>
<div className="image-preview-container">
  {images.map((file, index) => (
    <div key={index} className="image-preview-box">
      <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} className="image-preview" />
      <button type="button" className="remove-photo-button" onClick={() => removeImage(index)}>
        <i className="fas fa-times"></i>
      </button>
    </div>
  ))}
</div>

              </>
            )}

            <button type="submit" className="submit-button" disabled={!isFormValid || loading}>
              {loading ? (
                <>
                  <span>Registering...</span>
                  <div className="dots">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                `Register as ${isWinery ? 'Winery' : 'User'}`
              )}
            </button>
          </form>
        
          <div className="login-link">
            Already have an account? <Link to="/LoginPage">Login here</Link>
          </div>
        </div>
      </div>
      <Footer/>
      <Modal
  isOpen={cropModalOpen}
  onRequestClose={handleCancelCrop}
  ariaHideApp={false}
  contentLabel="Crop Image"
  style={{
    content: {
      width: '90%',  // Use percentages for better responsiveness
      maxWidth: '400px', // Max width for larger screens
      height: '70%', // Adjust height for mobile
      maxHeight: '500px', // Max height for larger screens
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // Center the modal
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      boxSizing: 'border-box', // Ensure padding doesn't affect width/height
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000, // Ensure it is above other elements
    },
  }}
>
  <div className="crop-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
    <Cropper
      image={croppingImage}
      crop={crop}
      zoom={zoom}
      aspect={1}
      onCropChange={setCrop}
      onZoomChange={setZoom}
      onCropComplete={onCropComplete}
    />
  </div>
  <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <button onClick={handleCancelCrop} className="cancel-button">Cancel</button>
    <button onClick={handleCropImage} className="crop-button">Confirm</button>
  </div>
</Modal>




    </>
  );
};

export default Register;